<template>
  <DxPivotGrid
    id="stock"
    :allow-sorting-by-summary="true"
    :allow-sorting="true"
    :allow-filtering="true"
    :allow-expand-all="true"
    :height="570"
    :show-borders="true"
    :data-source="dataSource"
  >
    <DxHeaderFilter :show-relevant-values="true" :width="300" :height="400">
      <!-- <DxSearch :enabled="true" /> -->
    </DxHeaderFilter>
    <DxFieldChooser :allow-search="true" />
    <DxFieldPanel :visible="true" />
    <DxExport :enabled="true" />
  </DxPivotGrid>
</template>
<script>
import {
  DxPivotGrid,
  DxHeaderFilter,
  // DxSearch,
  DxFieldChooser,
  DxFieldPanel,
  DxExport,
} from 'devextreme-vue/pivot-grid'
// import { BButton, BFormCheckbox, BCard } from 'bootstrap-vue'
import config from '@/config/config'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'
import { createStore } from 'devextreme-aspnet-data-nojquery'
import { formatDate } from '@/@core/utils/filter'

export default {
  components: {
    DxPivotGrid,
    DxHeaderFilter,
    // DxSearch,
    DxFieldChooser,
    DxFieldPanel,
    DxExport,
    // BButton,
    // BFormCheckbox,
    // BCard,
  },
  data() {
    return {
      dataSource: new PivotGridDataSource({
        remoteOperations: true,
        store: createStore({
          key: 'kd_produk',
          loadUrl: `${config.apiUrl}/api/auth/report/transaction`,
          onBeforeSend: (method, ajaxOptions) => {
            // ajaxOptions.xhrFields = { withCredentials: true }
            ajaxOptions.headers = {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }
          },
        }),
        fields: [
          {
            caption: 'Order',
            dataField: 'no_invoice',
            area: 'row',
            sortBySummaryField: 'price',
            sortBySummaryPath: [],
            sortOrder: 'asc',
            width: 250,
            // expanded: true,
          },
          {
            caption: 'Date',
            dataField: 'order_date',
            area: 'column',
            dataType: 'date',
            format: 'YYYY-MM-dd',
            groupName: 'date',
          },
          {
            groupName: 'date',
            groupInterval: 'year',
            // expanded: true,
          },
          {
            groupName: 'date',
            groupInterval: 'quarter',
            // expanded: true,
          },
          {
            groupName: 'date',
            groupInterval: 'month',
            // expanded: true,
          },
          {
            groupName: 'date',
            groupInterval: 'day',
            // expanded: true,
          },
          {
            caption: 'Order Status',
            dataField: 'order_status',
            dataType: 'string',
            area: 'column',
          },
          {
            caption: 'Sales Amount',
            dataField: 'price',
            summaryType: 'sum',
            area: 'data',
            dataType: 'number',
            format: {
              type: 'fixedPoint',
              precision: 2,
            },
          },
          {
            caption: 'Shipment Price',
            dataField: 'courier_price',
            groupName: 'no_invoice',
            summaryType: 'avg',
            area: 'data',
            dataType: 'number',
            format: {
              type: 'fixedPoint',
              precision: 2,
            },
          },
          {
            caption: 'Courier Insurance',
            dataField: 'insurance_value',
            summaryType: 'avg',
            area: 'data',
            dataType: 'number',
            format: {
              type: 'fixedPoint',
              precision: 2,
            },
          },
          {
            caption: 'Voucher Usage Value',
            dataField: 'voucher_usage_value',
            summaryType: 'avg',
            area: 'data',
            dataType: 'number',
            format: {
              type: 'fixedPoint',
              precision: 2,
            },
          },
          {
            caption: 'Voucher Usage Type',
            dataField: 'voucher_usage_type',
            area: 'row',
            dataType: 'string',
          },
        ],
      }),
    }
  },
}
</script>
